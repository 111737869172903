.toast-moonlight {
  min-width: 200px;
  padding: 10px 20px;
  border-radius: 5px;
  background: linear-gradient(to bottom, #1c1c1c, #535353);
  box-shadow: 0 0 10px rgba(83, 83, 83, 0.5);
  color: #fff;
  text-align: center;
}

.toast-moonlight-dark {
  min-width: 200px;
  padding: 10px 20px;
  border-radius: 5px;
  background: linear-gradient(to bottom, #ffffff, #535353);
  box-shadow: 0 0 10px rgba(83, 83, 83, 0.5);
  color: #000;
  text-align: center;
}